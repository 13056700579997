import React from "react";

function Home() {
	return (
		<div className="flex justify-center items-center">
			<ul className="mt-auto text-center">
				<li className="btn border-[#0B6836] p-4 border hover:border-none hover:text-white hover:bg-[#0B6836] bg-white text-[#0B6836] mt-6">
					<a href="/smartBuy">SmartBuy</a>
				</li>
				<li className="btn border-[#0B6836] p-4 border hover:border-none hover:text-white hover:bg-[#0B6836] bg-white text-[#0B6836] mt-6">
					<a href="/smartListing">SmartListing</a>
				</li>
				<li className="btn border-[#0B6836] p-4 border hover:border-none hover:text-white hover:bg-[#0B6836] bg-white text-[#0B6836] mt-6">
					<a href="/smartAgent">SmartAgent</a>
				</li>
				<li className="btn border-[#0B6836] p-4 border hover:border-none hover:text-white hover:bg-[#0B6836] bg-white text-[#0B6836] mt-6">
					<a href="/search">Search Properties</a>
				</li>
			</ul>
		</div>
	);
}

export default Home;
