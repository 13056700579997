import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "../src/components/Home";
import MainComponent from "./components/MainComponent";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/search" element={<MainComponent />} />
			</Routes>
		</Router>
	);
}

export default App;
