import React, { useEffect, useState } from "react";

import { House } from "../interfaces/interfaces";
import { fetchResults } from "../kerkesa/fetchResults";

const MainComponent: React.FC = () => {
	const [data, setData] = useState<House[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const getData = async () => {
			try {
				const result = await fetchResults();
				setData(result.data);
			} catch (error) {
				console.error("Error fetching data:", error);
			} finally {
				setLoading(false);
			}
		};

		getData();
	}, []);

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{data.length === 0 ? (
				<div>No data available</div>
			) : (
				<pre>{JSON.stringify(data, null, 2)}</pre>
			)}
		</div>
	);
};

export default MainComponent;
