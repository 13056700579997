export async function fetchResults() {
	const url = "http://localhost:4000/api/fetchResults"; // Proxy server URL

	try {
		const response = await fetch(url, {
			method: "GET",
			cache: "no-store",
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const result = await response.json();
		return result;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
}
